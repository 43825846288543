import React from "react"
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Newsletter from "../page-components/Newsletter"

const NewsletterPage = () => {
  
  return (
    <div>
        <SEO
            title="Best Product Management Newsletters"
        />
        <Header />
        <div className="section-container content">
        <h1 className="title"><span role="img" aria-label="newsletter">📨</span> PM Newsletters</h1>
        <p className="title-sub">product management gold for your inbox</p>

        <Newsletter
            newsletterTitle="Lenny's Newsletter"
            newsletterLink="https://vrlps.co/5FOh5Nj/cp"
            author="Lenny Rachinsky"
            img="https://cdn.substack.com/image/fetch/w_264,c_limit,f_auto,q_auto:best,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F40b73d36-7447-4f52-83b5-4bbf44b9324a_1200x1200.png"
            summary="Lenny consistently delivers high-quality, actionable content. And the community around it is great. You're not a serious PM if you haven't subscribed to this one."
        />

        <Newsletter
            newsletterTitle="The Beautiful Mess"
            newsletterLink="https://cutlefish.substack.com/"
            author="John Cutler"
            img="https://cdn.substack.com/image/fetch/w_264,c_limit,f_auto,q_auto:best,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fccf48548-b188-4c1c-8ddf-296017688c83_256x256.png"
            summary="John is like the crafty utility infielder that won't hit the ball out of the park, but always seems to be doing something useful"
        />

        <Newsletter
            newsletterTitle="Bring the Donuts Newsletter"
            newsletterLink="https://newsletter.bringthedonuts.com/"
            author="Ken Norton"
            img="https://cdn.substack.com/image/fetch/w_96,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F0fa725f2-c6ec-4820-bc29-a79c51477477_1280x1280.png"
            summary="Ken is the OG of PMs. I have nothing more to say."
        />

        <Newsletter
            newsletterTitle="Product Life"
            newsletterLink="https://willlawrence.substack.com/"
            author="Will Lawrence"
            img="https://cdn.substack.com/image/fetch/w_96,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fdf7955b1-8264-4df0-a6aa-5609d518d4df_1280x1280.png"
            summary="Will's delivered some quality content about his time at Facebook, but seems to have lost focus from the newsletter to start his own community of PMs"
        />

        <Newsletter
            newsletterTitle="Gibson Biddle's &quot;Ask Gib&quot; Product Newsletter"
            newsletterLink="https://askgib.substack.com/"
            author="Gibson Biddle"
            img="https://cdn.substack.com/image/fetch/w_1360,c_limit,f_auto,q_auto:best,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F6699bc0f-f3d0-4ed8-a6e4-e67976bbe2b4_1280x1280.png"
            summary="Gib likes to talk about himself a lot, but seems like a nice enough guy with positve energy – just look at his face"
        />

       </div>
       <Footer />
    </div>
  )
}

export default NewsletterPage