import React from "react"

import newsletterStyles from './newsletter.module.scss'

const Newsletter = (props) => {

    return (
        <a className={newsletterStyles.a} href={props.newsletterLink} target="_blank" rel="noreferrer">
            <div  className={newsletterStyles.newsletterContainer}>
                <div className={newsletterStyles.title}>
                    <img src={props.img} alt={props.newsletterTitle} class="logo"/>
                    <div>
                        <h1>{props.newsletterTitle}</h1>
                        <p className={newsletterStyles.author}>by {props.author}</p>
                    </div>
                </div>
            
                <p>{props.summary}</p>
            </div>
        </a>
    )
}

export default Newsletter